<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center my-6>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <v-flex
            xs12
            sm9
            md9
            lg10
            text-start
            align-center
            mt-2
            class="headingChurch"
            >Parish Managers List</v-flex
          >
          <v-flex xs12 sm3 md3 lg2 text-end align-center
            ><v-btn
              class="churchbtn"
              color="blue"
              style="color: white"
              @click="dialog = true"
              >ASSIGN USER</v-btn
            ></v-flex
          >
          <v-dialog v-model="dialog" max-width="700px">
            <v-card rounded="lg">
              <v-card-title>
                <span class="editbox">ASSIGN PARISH MANAGERS</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>

              <v-card-text>
                <v-form>
                  <v-layout wrap justify-center px-2>
                    <v-flex xs12 pr-2>
                      <span class="label">Select User</span>
                      <v-autocomplete
                        class="pt-2"
                        v-model="user"
                        :items="userArray"
                        dense
                        outlined
                        clearable
                        :search-input.sync="search"
                        item-text="name"
                        item-value="_id"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 pr-2>
                      <span class="label">Select Church</span>
                      <v-autocomplete
                        class="pt-2"
                        v-model="churchIds"
                        :items="churchArray"
                        dense
                        outlined
                        multiple
                        clearable
                        :search-input.sync="search1"
                        item-text="name"
                        item-value="_id"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-btn outlined color="grey" text @click="dialog = false"
                  ><span style="color: black">Cancel</span>
                </v-btn>
                <v-btn color="primary" @click="validateInput()"
                  >Save Changes</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- table Section -->

        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-data-table
              :headers="headers"
              :items="managerslist"
              hide-default-footer
              class="elevation-1 text--black"
              id="virtual-scroll-table"
              @click:row="redirectToViewPage" style="cursor: pointer;"
            >
              <!-- <template v-slot:[`item.description`]="{ item }">
                                <div class="truncated" @click="showFullDescription(item.description)">
                                    {{ item.description.slice(0, 50) }}....
                                </div>
                            </template> -->
              <template v-slot:[`item._id`]="{ item }">
               
                <v-icon
                  small
                  color="red"
                  class="ml-1"
                  @click.stop="openDeleteDialog(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!--edit dialog  -->
    <!-- <v-dialog v-model="editdialog" max-width="800px">
            <v-card rounded="lg">
                <v-card-title>
                    <span class="editbox">EDIT Country</span>
                    <v-spacer></v-spacer>

                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-layout wrap justify-center px-2>



                            <v-flex xs12 pr-2>
                                <span class="label ">
                                    Name
                                </span>

                                <v-text-field class="pt-2 text-des" style="font-size:14px"
                                    v-model="editingitem.name" outlined dense hide-details></v-text-field>
                            </v-flex>


                           
                        </v-layout>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="py-4 justify-end headline grey lighten-2">
                    <v-btn outlined color="grey" text @click="editdialog = false"><span
                            style="color: black;">Cancel</span>
                    </v-btn>
                    <v-btn color="primary" @click="edit(editingitem._id)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

    <!-- delete dialog -->

   <v-dialog v-model="deletedialog" max-width="600"> 
            <v-card class="dialog-card">
                <v-card-title class="grey lighten-2 d-flex justify-center">
                    <v-icon color="red" size="32">mdi-alert</v-icon>
                    <span class="ml-2">Confirm Deletion</span>
                </v-card-title>
                <v-card-text class="py-5 text-center text-des">
                    <div class="body-1">
                        Are you sure you want to unassign this Parish Manager?
                        <br />
                        This action <strong>cannot be undone</strong>.
                    </div>
                    <v-divider class="my-3"></v-divider>
                    <v-row class="pt-2" no-gutters>
                        <v-col>
                            <img src="@/assets/images/manager1.png" width="40px" height="40px" class="dialog-icon" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pa-2">
                    <v-btn style="font-family: interregular; font-size: 13px; color: white;" color="#cf3a45"
                        @click="deletedialog = false">Cancel</v-btn>
                    <v-btn style="font-family: interregular; font-size: 13px; color: white;" color="blue darken-1"
                        @click="confirmDelete">OK</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog> 
  </div>
</template>

<script>
import axios from "axios";
//import { search } from 'core-js/fn/symbol';
export default {
  data() {
    return {
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      appLoading: false,
      search: "",
      search1: "",
      itemToDelete: null,
      deletedialog: false,
      dialog: false,
      userArray: [],
      churchIds: [],
      user: [],
      role: "user",
      managerslist: [],
      churchArray: [],
      church: "",
      headers: [
        { text: "Name", value: "name", width: "300px" },
        { text: "Email", value: "email", width: "300px" },
        { text: "Phone", value: "phoneNumber", width: "200px" },
        // { text: "Role", value: "role", width: "50px" },

         { text: "Actions", value: "_id", width: "15px", }
      ],
    };
  },
  mounted() {
    this.getData();
    this.getParishManagers();
    this.getChurch();
  },
  watch: {
    search(val) {
      this.loading = true;
      setTimeout(() => {
        this.getData(val);
        this.loading = false;
      }, 500);
    },
    search1(val) {
      this.loading = true;
      setTimeout(() => {
        this.getChurch(val);
        this.loading = false;
      }, 500);
    },
    // church() {
    //   this.getChurch();
    //      },
  },
  methods: {
    redirectToViewPage(item) {
        this.$router.push('/singleViewManagers?id=' + item._id);
      },
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/list",
        method: "GET",
        params: {
          role: this.role, // Pass the role parameter here
          keyword: this.search,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.userArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getChurch(val) {
      this.appLoading = true;
      //   const currentTime = new Date().toISOString(); // Get current time in ISO format
      axios({
        url: "/get/unassigned/church/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: val,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.churchArray = response.data.data;
          // this.pages = response.data.pages;
          // this.totalData = response.data.count;
          //this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getParishManagers() {
      this.appLoading = true;
      axios({
        url: "/parish/manager/list",
        method: "GET",
        // params: {
        //   role: this.role, // Pass the role parameter here
        // },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.managerslist = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    // itemadd() {
    //     var data = {};
    //     data["name"] = this.name;

    //     axios({
    //         url: "/add/new/country",
    //         method: "POST",
    //         data: data,
    //         headers: {
    //             token: localStorage.getItem("token"),
    //         },
    //     })
    //         .then((response) => {
    //             this.appLoading = false;
    //             if (response.data.status) {
    //                 this.msg = "Added Sucessfully";
    //                 this.showsnackbar = true;
    //                 this.dialog = false;
    //                 this.name = null;

    //                 this.getData();
    //             } else {
    //                 this.msg = response.data.msg;
    //                 this.showsnackbar = true;
    //             }
    //         })
    //         .catch((err) => {
    //             this.appLoading = false;
    //             this.ServerError = true;
    //             console.log(err);
    //         });
    // },
    // getData() {
    //     this.appLoading = true;
    //     axios({
    //         url: "/get/country/list",

    //         method: "GET",
    //         headers: {
    //             token: localStorage.getItem("token"),
    //         },
    //         params: {
    //             //  count: this.count,
    //             // page: this.currentPage,

    //         },
    //     })
    //         .then((response) => {
    //             this.appLoading = false;
    //             this.countrylist = response.data.data;
    //             // this.pages = response.data.pages;
    //             // this.totalData = response.data.count;
    //         })
    //         .catch((err) => {
    //             this.appLoading = false;
    //             this.ServerError = true;
    //             console.log(err);
    //         });
    // },

    // editSlider(item) {
    //     this.editingitem = item;
    //     this.editdialog = true;

    // },

    validateInput() {
  if (!this.user ||  this.user.length === 0) {
    this.msg = "Please select a user";
    this.showSnackBar = true;
    return;
  } 
  // Check if churchIds array is empty
  else if (!this.churchIds || this.churchIds.length === 0) {
    this.msg = "Please select a church";
    this.showSnackBar = true;
    return;
  } 
  else {
    this.assignUser();
  }
},

    assignUser() {
      this.appLoading = true;

      const selectedUserId = this.user; // Get the selected user's _id from v-autocomplete
      const selectedChurchIds = this.churchIds; // Get the selected churches' _ids from v-autocomplete

      // Prepare the payload to be sent to the backend
      const payload = {
        id: selectedUserId,
        churchIds: selectedChurchIds,
      };

      axios({
        url: "/assign/parish/manager",
        method: "POST",
        data: payload,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialog = false;
            this.getParishManagers();
            this.user = null; // Reset user selection
            this.churchIds = []; // Reset church selection
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/unassign/parish/manager",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getParishManagers();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.dialog-card {
  font-family: interbold;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dialog-icon {
  animation: pulse 1s infinite alternate;
}

.dialog-button {
  min-width: 120px;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.7;
  }

  to {
    transform: scale(1.1);
    opacity: 1;
  }
}
.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
  background-color: white;
  table-layout: fixed; /* Fixes column widths to prevent stretching */
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: white;
  font-family: interbold;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.table td {
  font-family: interregular;
  font-size: 15px;
}

.table-row:hover {
  background-color: #eff2f6;
}

.actions-column {
  text-align: center; /* Center-aligns the action buttons */
}
</style>